/** @jsxImportSource theme-ui */
import React, { useState } from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import * as prismicH from "@prismicio/helpers";
import { PrismicRichText } from "@prismicio/react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Modal from "react-modal";
import { FaArrowRight, FaTimes } from "react-icons/fa";
import SEO from "../components/Seo";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import LightGallery from "lightgallery/react";
import Masonry from "react-masonry-css";

// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "transparent",
    border: "none",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.90)",
    zIndex: 20,
  },
};
// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#___gatsby");

const DancersPage = ({ data }) => {
  const [modalState, setModalState] = useState(false);
  const [modalIndex, setModalIndex] = useState(0); //index of headshot

  const pageData = data.prismicDancers.data;
  //   const photos = pageData.gallery.map((item, index) => {
  //     const image = getImage(item.image);
  //     return { src: image };
  //   });
  //   console.log("photos", photos);

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1,
  };

  const onInit = () => {
    console.log("lightGallery has been initialized");
  };
  const openModal = (src, index) => {
    console.log("index", index);
    setModalIndex(index);
    //console.log("modalIndex", modalIndex);
    setModalState(true);
  };
  const closeModal = () => {
    setModalState(false);
  };

  console.log(pageData.gallery[modalIndex].image);

  return (
    <Layout>
      <SEO
        title="Contempory and Commercial Dancers Headshots Bodyshots London"
        description="London contemporary and commercial dance photographer based in Woolwich. Professional portrait photographer offering headshots and body shots for dancers"
      />
      <div>
        <div
          sx={{
            fontSize: 4,
            color: "muted",
            h1: {
              color: "black",
            },
          }}
        >
          <h1>{pageData.title.text}</h1>
          <PrismicRichText field={pageData.text.raw} />
        </div>
      </div>

      <div
        sx={{
          //px: [3, 2, 1],
          "& .photo-grid": {
            display: "flex",
            marginLeft: "-10px" /* gutter size offset */,
            width: "auto",

            ".photo-grid_column": {
              paddingLeft: "10px" /* gutter size */,
              backgroundClip: "padding-box",
            },
            figure: {
              margin: 0,
              mb: "10px",
            },
          },
        }}
      >
        <LightGallery
          onInit={onInit}
          speed={500}
          download={false}
          selector={".galleryItemButt"}
        >
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="photo-grid"
            columnClassName="photo-grid_column"
          >
            {pageData.gallery.map((item, index) => {
              const image = getImage(item.image);
              return (
                <a
                  className="galleryItemButt"
                  key={`dancerItem${index}`}
                  data-src={item.image.url}
                  //   data-sub-html={`<h3>${item.image.alt}</h3>`}
                >
                  <figure
                    className="galleryItem"
                    data={index}
                    //sx={headshotStyles}
                    //onClick={(e) => openModal(item)}
                  >
                    <GatsbyImage
                      image={image}
                      alt={item.image.alt}
                      //  sx={{
                      //     "& img": { border: "1px solid black", objectFit: "cover" },
                      //   }}
                    />
                    {/* <figcaption>{item.name.text}</figcaption> */}
                  </figure>
                </a>
              );
            })}
          </Masonry>
        </LightGallery>
      </div>

      <Modal
        isOpen={modalState}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
        style={modalStyles}
        contentLabel="Image Lightbox Modal"
      >
        <div
          sx={{
            color: "white",
            display: "flex",
            fontSize: "1.25rem",
            justifyContent: "right",
            cursor: "pointer",
            // marginRight: "2em",
            // marginBottom: "0.3em",
          }}
          onClick={closeModal}
        >
          <FaTimes />
        </div>
        <div
          sx={{
            //width: "1000px",
            maxWidth: "90vw",
            maxHeight: "90vh",
            textAlign: "center",
            "& .gatsby-image-wrapper": {
              width: "100%",
              maxWidth: "95vw",
              height: "100%",
              maxHeight: "85vh",
            },
            "& img": {
              //border: "1px solid black",
              objectFit: "contain !important",
            },
          }}
        >
          <GatsbyImage image={getImage(pageData.gallery[modalIndex].image)} />
        </div>
      </Modal>
      <div
        sx={{
          py: 5,
          fontSize: 4,
          color: "muted",
          h1: {
            color: "black",
          },
        }}
      >
        <PrismicRichText field={pageData.text_bottom.raw} />
      </div>
    </Layout>
  );
};

export default DancersPage;

export const query = graphql`
  query dancersPageQuery {
    prismicDancers {
      _previewable
      data {
        gallery {
          image {
            alt
            gatsbyImageData
            url
          }
        }
        text {
          richText
          text
          raw
        }
        text_bottom {
          richText
          text
          raw
        }
        title {
          richText
          text
        }
      }
      last_publication_date
    }
  }
`;
